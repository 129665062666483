import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Current Pursuits" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/yy7NyeHycVI"
        }}>{`Experimenting With Youtube`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/9vz06QO3UkQ"
        }}>{`Trying to make small impacts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enchanting-grease-1fd.notion.site/Open-Source-2022-40c05e3737134afbb309a5fe1a87e24c"
        }}>{`Open Source Content Consumption`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      