import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`Since you have decided to visit my little cave of `}<strong parentName="p">{`curiosities`}</strong>{`, let's quickly get you started with one of my favorite qoutes`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/W4UNlQK7QhE8o/giphy.gif",
        "alt": "destiny"
      }}></img></p>
    <h3>{`Professional`}</h3>
    <ul>
      <li parentName="ul">{`Software engineer, playing with fire at an early stage `}<a parentName="li" {...{
          "href": "https://commenda.io/"
        }}>{`startup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cal.com/aman-tulsyan"
        }}>{`Tools to automate personal workflows`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`Future Predictions`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amantulsyan.com/personal-databases"
        }}>{`Personal Databases`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amantulsyan.com/accelerated-learning"
        }}>{`Accelarated Learning`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`Recreational Interests`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/amantulsyan35/recreational"
        }}>{`Recreational Programming`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/amantulsyan35/questions"
        }}>{`Maintaining a public of repository of questions, that frequently take my smoll headspace`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enchanting-grease-1fd.notion.site/Open-Source-Content-Consumption-40c05e3737134afbb309a5fe1a87e24c?pvs=25"
        }}>{`Playing around with content consumption`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amantulsyan.com/home-cooked-application-logs"
        }}>{`Building home cooked applications`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amantulsyan.com/gated-societies-as-an-economic-model-wip"
        }}>{`Occasional opinions about economics and humanities`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enchanting-grease-1fd.notion.site/Clips-aaf8889174264d3a8f156eda25d40694"
        }}>{`Capturing Context`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`Time for Invitation`}</h3>
    <p>{`It's an invitation for you to explore the stories that moved me, characters that resonated and worlds that captivated me.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.notion.so/01-748e3585ac204227b236d3bb466fa89b?pvs=4"
        }}>{`Everything Culture`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enchanting-grease-1fd.notion.site/My-Reading-List-77bf2f0f354b4baa9920cbe9f02a59a6"
        }}>{`Everything Reading`}</a></li>
    </ul>
    <h5>{`Groovin' in Progress`}</h5>
    <iframe style={{
      "borderRadius": "12px"
    }} src="https://open.spotify.com/embed/track/6sJqChpMdTKkD94uEoDNoE?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    <hr></hr>
    <h5>{`One last thing!`}</h5>
    <p>{`Don't shy away from `}<a parentName="p" {...{
        "href": "amantulsyan35@gmail.com"
      }}>{`emailing me`}</a>{`(if you are more of an old school type) or book a `}<a parentName="p" {...{
        "href": ""
      }}>{`cal meeting`}</a>{` if you think we can be friends ;)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      